import getOriginalCountryOfOrigin from '../../utilities/country-of-origin';
import { hasVendorItemUpdate, hasVendorPoUpdate, hasQtyUpdate, hasPriceUpdate, hasWeightUpdate, hasCountryOfOriginUpdate } from '../validation/vendor-update-check';

const getOCSBody = (po, poItemDetails, currentUser) => {
    // if PO was not updated, auto-confirm shipment 
    if (hasVendorPoUpdate(poItemDetails) === false) {
        return { 
            poId: po.purchaseOrderId,
            vendorInvoiceNumber: po.invoiceNumber,
            isAutoApproved: true
        }
      } else {
        const poItemUpdates = poItemDetails.map((item) => {
          const originalCoo = getOriginalCountryOfOrigin(item);
          let retVal = {
            poDetailId: item.purchaseOrderDetailId,
            itemCode: ('item' in item && item.item !== null) ? item.item.itemCode : null,
            originalQty: item.arQty,
            qty: hasQtyUpdate(item) ? parseInt(item.newQty) : null,
            originalCountryOfOrigin: originalCoo,
            countryOfOrigin: hasCountryOfOriginUpdate(item) ? item.item.newCountryOfOrigin : null,
            originalPrice: item.price,
            price: hasPriceUpdate(item) ? parseFloat(item.newPrice).toFixed(2) : null,
            originalTotalWeight: item.totalWeight,
            totalWeight: hasWeightUpdate(item) ? parseFloat(item.newTotalWeight).toFixed(2) : null,
            description: ('item' in item && item.item !== null) ? item.item.description : null,
            hi: ('item' in item && item.item !== null) ? item.item.palletHigh : null,
            ti: ('item' in item && item.item !== null) ? item.item.palletTie : null,
            unitWeight: ('item' in item && item.item !== null) ? item.item.grossWeight : null,
            unitType: item.unitType,
            cartonPallet: item.cartonPallet,
            groupCode: ('item' in item && item.item !== null) ? item.item.itemGroupCode : null
          }

          // if item was not updated, auto-approve item
          if (!hasVendorItemUpdate(item) ) {
            retVal['confirmationDate'] = new Date(new Date().getFullYear(), new Date().getMonth() , new Date().getDate());
            retVal['isApproved'] = true;
          }

          // check if qty, price, coo or totalwWieght is null and remove them from the object if they are
          // OCD is expecting these properties only if the vendor made changes.
          if (retVal.qty === null) delete retVal.qty;
          if (retVal.price === null) delete retVal.price;
          if (retVal.totalWeight === null) delete retVal.totalWeight;
          if (retVal.countryOfOrigin === null) delete retVal.countryOfOrigin;

          return retVal;
        });

        const formatDate = (dt) => {
          return (typeof(dt) === 'string' && dt.includes('T')) ? dt.split('T')[0] : dt;
        }

        let body =  {
          vendorId: po.vendorCode,
          buyerId: po.buyerId,
          invoiceNumber: po.invoiceNumber,
          poDetail: JSON.stringify(poItemUpdates),
          loadDate: formatDate(po.loadDate),
          arrivalDate: formatDate(po.arrivalDate),
          woId: po.wo_id,
          createdBy: currentUser,
          customerId: po.customerCode,
          pickupNumber: po.pickupNumber,
          coolerCode: po.coolerCode,
          originalTotalWeight: po.totalWeight.toString(),
          originalTotalCost: po.totalCost.toString(),
          originalTotalCases: po.totalCartons.toString(),
          originalTotalPallets: po.totalPallets.toString(),
          category: po.poType,
          originalPricingStatusCode: po.wo_pricingStatusCode,
          originalTruckStatusCode: po.sh_truckStatusCode,
          isAccepted: null
        };

        return body;
    }
}

export default getOCSBody;