/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import {
  DropDown, PrimaryButton, Pulse
} from 'wf-react-component-library';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import ErrorModal from './ErrorModal';
import { getShipmentsBySearchCriteria } from '../services/api/SCAApiService';

import 'react-dates/lib/css/_datepicker.css';
import './Search.scss';

import StickyWrapper from './StickyWrapper';

const logger = new Logger('Search.js');

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerDetails: this.props.customerDetails,
      customerDetails: [],
      buyerDetails: [],
      vendorDetails: this.props.vendorDetails,
      dateOptions: {
        0: 'Load Date',
        1: 'Arrival Date',
      },
      startDate: null,
      endDate: null,
      dateType: null,
      isSearching: false,
      displayNoItemsFound: false,
      displayPartialResultsFound: false,
      errorMsg: '',
      showSearchBar: true
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.resetPurchaseOrderDetails = this.resetPurchaseOrderDetails.bind(this);
    this.removeErrorMessages = this.removeErrorMessages.bind(this);
    this.handleHideShowSearchBar = this.handleHideShowSearchBar.bind(this);
  }

  componentDidMount = () => {
    this.handleReset();
  }

  componentDidUpdate = async(prevProps) => {
    const { currentUserVendorCode } = this.props;
    if (currentUserVendorCode !== prevProps.currentUserVendorCode) {
      this.setState({ vendorDetails: this.props.vendorDetails });
      this.handleSearch();
    }
  }

  handleSearch = (buttonClick) => {
    const { dateType, startDate, endDate } = this.state;

    //Check for dateType and at least one date is selected
    const dateSelected = [startDate, endDate].some(val => val !== null);
    const typeSelected = ['0', '1'].includes(dateType);
    if (buttonClick && (!dateSelected || !typeSelected)) {
      this.setState({ errorMsg: 'Please select a date for your search or click the "Reset" button to return all Purchase Orders.' });
      return;
    }

    this.setState({ isSearching: true, displayNoItemsFound: false, displayPartialResultsFound: false, purchaseOrderDetails: [], customerDetails: [], buyerDetails: [] })
    
    
    const params = {
      startDate: this.state.startDate === null ? null : this.state.startDate.format('YYYY-MM-DD'),
      endDate: this.state.endDate === null ? null : this.state.endDate.format('YYYY-MM-DD'),
      dateType: this.state.dateType
    }

    getShipmentsBySearchCriteria(params).then((shipmentDetails) => {
      try {
        logger.debug("Shipment Details");
        logger.debug(shipmentDetails);
        const statusCode = shipmentDetails.statusCode;
        let purchaseOrderDetails = [];
        let customerDetails = [];
        let buyerDetails = [];
        // Status code can be 200(success) or 206(partial results)
        if (statusCode === 200){
          purchaseOrderDetails = shipmentDetails.purchaseOrders;
          const customerCodesOnPOs = shipmentDetails.customers;
          const buyerIdsOnPOs = shipmentDetails.buyers;
          customerDetails = this.props.customerDetails
                                            .filter(c => customerCodesOnPOs.includes(c.code))
                                            .map(i => ({
                                              customerCode: i.code,
                                              description: i.description
                                            }));  
          buyerDetails = this.props.buyerDetails.filter(b => buyerIdsOnPOs.includes(b.buyerId));
        }
      
        const displayNoItemsFound = (statusCode === 200 && purchaseOrderDetails.length === 0);
        const displayPartialResultsFound = (statusCode === 206);
        this.setState({ purchaseOrderDetails, customerDetails, buyerDetails, isSearching: false, displayPartialResultsFound, displayNoItemsFound });     
      } catch {
        this.setState({ displayNoItemsFound: true, isSearching: false });
      }
    });
  }

  updateSearch = () => {
    // if (this.state.startDate === null || this.state.endDate === null) {
    //   this.setState({ errorMsg: 'Please select dates for your search.' });
    //   return;
    // }
    this.handleSearch(true);
  }

  handleDropDownChange = (e) => {
    this.resetPurchaseOrderDetails();
    this.setState({ dateType: e.target.value });
  }

  resetPurchaseOrderDetails = () => {
    const { purchaseOrderDetails } = this.state;

    this.setState({
      isSearching: false,
      displayNoItemsFound: false,
      displayPartialResultsFound: false
    });

    if (purchaseOrderDetails !== undefined && purchaseOrderDetails.length > 0) {
      this.setState({
        purchaseOrderDetails: [],
      });
    }
  }

  handleReset = () => {
    this.setState({
      customerDetails: [],
      buyerDetails: [],
      dateType: "0",
      startDate: moment(),
      endDate: null,
      purchaseOrderDetails: [],
      isSearching: false,
      displayNoItemsFound: false,
      displayPartialResultsFound: false
    },
    () => this.handleSearch());
  }

  removeErrorMessages = () => this.setState({ errorMsg: '' });

  handleHideShowSearchBar = () => {
    const { showSearchBar } = this.state;
    this.setState({ showSearchBar: !showSearchBar});
  }

  render() {
    const {
      customerDetails,
      buyerDetails,
      dateOptions,
      purchaseOrderDetails,
      isSearching,
      displayNoItemsFound,
      displayPartialResultsFound,
      dateType,
      vendorDetails,
      showSearchBar,
      errorMsg
    } = this.state;

    const { currentUser, countryOfOrigins, vendorCoolers } = this.props;

    return (
      <div className="App">
        { errorMsg.length > 0
          ? <ErrorModal showErrorModal={this.state.errorMsg.length > 0} errorMessages={this.state.errorMsg} closeModal={this.removeErrorMessages} />
          : null
        }
        <StickyWrapper offset={90}>
          { vendorDetails === undefined
            ? null
            : <div className="flex-container-row vendor-title">
                  <div className="flex-container-column">
                    Viewing Purchase Orders for: {vendorDetails.description} ({vendorDetails.code})
                  </div>
                  <div className="flex-container-column">
                    <button className="toggle-search-bar" type="button" onClick={this.handleHideShowSearchBar}>{ showSearchBar ? 'Hide' : 'Show' } Search Bar</button>
                  </div>
              </div>
          }

          { showSearchBar
              ? <div className="search-criteria flex-container-row">
                  <div className="flex-container-column" style={{ paddingBottom: '30px' }}>
                    <DropDown id="dateOptions" labelName="Date" options={dateOptions} value={dateType} placeHolderText="Select a Date Option" handleChange={this.handleDropDownChange} />
                  </div>

                  <div className="flex-container-column" style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '30px' }}>
                    <div className="wf-form-element-label">
                      From:
                      <span style={{ paddingLeft: '110px' }}>To:</span>
                    </div>
                    <DateRangePicker
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="start-dt" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="end-dt" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => {
                        this.resetPurchaseOrderDetails();
                        this.setState({ startDate, endDate })}
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                      isOutsideRange={(day) => day.isBefore(moment().subtract(1000, 'day'))}
                      minimumNights={0}
                      readOnly={true}
                    />
                  </div>

                  <div className="flex-container-column">
                    <div className="flex-container-row search-buttons">
                      <PrimaryButton onClick={this.updateSearch} name="Search" />
                      <PrimaryButton onClick={this.handleReset} name="Reset" />
                    </div>
                  </div>
                </div>
          : null
          }
        </StickyWrapper>

        { isSearching
          ? <div className="flex-container-row"><Pulse text="Loading Purchase Orders..." /></div>
          : null
        }

        { displayNoItemsFound
          ? <div className="flex-container-row">
              <div className="pulse-container headline-small">No POs Found</div>
            </div>
          : null
        }

        { displayPartialResultsFound
          ? <div className="flex-container-row">
              <div className="pulse-container headline-small"><br />Search exceeds query limits. Limit your results by narrowing your date range.</div>
            </div>
          : null
        }

        { isSearching || purchaseOrderDetails === undefined || purchaseOrderDetails.length === 0
          ? null
          : <PurchaseOrderDetails handleSearch={this.handleSearch} customerDetails={customerDetails} buyerDetails={buyerDetails} searchId={Math.floor(1000000 * Math.random()) * 100000000} currentUser={currentUser} purchaseOrderDetails={purchaseOrderDetails} countryOfOrigins={countryOfOrigins} vendorCoolers={vendorCoolers}  />
        }
      </div>
    );
  }
}

export default Search;
