
import getOriginalCountryOfOrigin from '../../utilities/country-of-origin';

const hasVendorItemUpdate = (item) => {
	const countryOfOrigin = getOriginalCountryOfOrigin(item);
	let conditions = [];
	conditions.push(hasCountryOfOriginUpdate(item));
	conditions.push(hasQtyUpdate(item));
	conditions.push(hasPriceUpdate(item));
	conditions.push(hasWeightUpdate(item));
	return conditions.some(x => x === true)
}

const hasQtyUpdate = (item) => {
	return 'newQty' in item && parseInt(item.newQty) !== parseInt(item.qty);
}

const hasCountryOfOriginUpdate = (item) => {
	return item.item && item.item !== null && 'newCountryOfOrigin' in item.item && item.item.newCountryOfOrigin !== getOriginalCountryOfOrigin(item);
}

const hasPriceUpdate = (item) => {
	return 'newPrice' in item && parseFloat(item.newPrice) !== parseFloat(item.price);
}

const hasWeightUpdate = (item) => {
	return 'newTotalWeight' in item && parseFloat(item.newTotalWeight) !== parseFloat(item.totalWeight);
}

const hasVendorPoUpdate = (poItemDetails) => {
	return poItemDetails.some((item) => hasVendorItemUpdate(item))
}

export { hasVendorItemUpdate, hasVendorPoUpdate, hasQtyUpdate, hasPriceUpdate, hasWeightUpdate, hasCountryOfOriginUpdate };
