/* eslint-disable arrow-body-style */
import { API, Auth, Logger } from 'aws-amplify';

const logger = new Logger('SCSApiService.js');
const SCS_API_NAME = 'SCSApiProxy';

/**
 * Request Options to include authorization headers on all requests
 */
const requestOptions = () => {
  logger.debug('requestOptions..');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'x-api-authorization': `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
          'idToken': `${user.signInUserSession.idToken.jwtToken}`
        },
      };
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
};

/**
 * Request Wrapper to call the request options on all requests
 * @param {*} request
 */
const requestWrapper = (request) => {
  return requestOptions().then((options) => request(options));
};

const putPurchaseOrder = (poId, body) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options, { body });
    return API.put(SCS_API_NAME, `/purchaseOrders/${poId}`, options);
  });
};

const getAllPurchaseOrders = () => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.get(SCS_API_NAME, `/purchaseOrders`, options);
  });
};

const getPurchaseOrder = (poId) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.get(SCS_API_NAME, `/purchaseOrders/${poId}`, options);
  });
};

const deletePurchaseOrder = (buyerId, poId) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.del(SCS_API_NAME, `/buyers/${buyerId}/purchaseOrders/${poId}`, options);
  });
};

const getPurchaseOrders = (buyerId, params) => {
  return requestWrapper((options) => {
    let {
      startDate,
      endDate,
      dateType,
      customerCode,
      vendorCode,
    } = params;
    // format date types
    const fromDateParam = dateType === "0"
      ? 'loadDateFrom'
      : 'arrivalDateFrom';
    const toDateParam = dateType === "0"
      ? 'loadDateTo'
      : 'arrivalDateTo'

    // form url and queryParams
    const url = `/buyers/${buyerId}/purchaseOrders?vendorCode=${vendorCode}&customerCode=${customerCode}`;
    let queryParams = startDate
      ? `&${fromDateParam}=${startDate}`
      : '';
    queryParams = endDate
      ? `${queryParams}&${toDateParam}=${endDate}`
      : queryParams;

    return API.get(SCS_API_NAME, `${url}${queryParams}`, options);
  });
}

const putAuditConfirmedOrders = (body) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options, { body });
    return API.put(SCS_API_NAME, '/purchaseOrders/auditConfirmedOrders', options);
  });
};

export {
  getPurchaseOrder,
  deletePurchaseOrder,
  getAllPurchaseOrders,
  getPurchaseOrders,
  putPurchaseOrder,
  putAuditConfirmedOrders,
};
