/* eslint-disable arrow-body-style */
import { API, Auth, Logger } from 'aws-amplify';

const logger = new Logger('ConfirmOrderApiService');
const CONFIRM_ORDER_API_NAME = 'ConfirmOrderApiProxy';

/**
 * Request Options to include authorization headers on all requests
 */
const requestOptions = () => {
  logger.debug('requestOptions..confirmOrders..');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        headers: {
            'Content-Type': 'application/json'
        },
      };
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
};

/**
 * Request Wrapper to call the request options on all requests
 * @param {*} request
 */
const requestWrapper = (request) => {
  return requestOptions().then((options) => request(options));
};

/**
 * Submit PO to confirmShipment api
 * @param {*} body
 */
const postPurchaseOrder = (body) => {
  return requestWrapper((options) => {
    // set message body
    Object.assign(options, { body });
    // make api request
    return API.post(CONFIRM_ORDER_API_NAME,'', options);
  });
};

export {
    requestWrapper,
    postPurchaseOrder
  };
   
