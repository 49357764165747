/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger } from 'aws-amplify';
import {
  SearchInput, PrimaryButton, Pulse, MultiSelectDropDown
} from 'wf-react-component-library';
import { putPurchaseOrder, putAuditConfirmedOrders } from '../services/api/SCSApiService';
import { getPurchaseOrderItemDetails } from '../services/api/SCAApiService';
import { postPurchaseOrder } from '../services/api/ConfirmOrderApiService';
import PurchaseOrderItemDetails from './PurchaseOrderItemDetails';
import PurchaseOrderDetailsLine1 from './PurchaseOrderDetailsLine1';
import PurchaseOrderDetailsLine2 from './PurchaseOrderDetailsLine2';
import ErrorModal from './ErrorModal';
import ConfirmationModal from './ConfirmationModal';
import ResetModal from './ResetModal';
import { hasNullTotalWeight, validateWeightAndQty } from '../services/validation/purchase-order-details-validation'
import 'react-dates/lib/css/_datepicker.css';
import './PurchaseOrderDetails.scss';
import StickyWrapper from './StickyWrapper';
import getOCSBody from '../services/data-manipulation/scs-data'

const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAllChecked: false,
      selectedItems: [],
      showOrderItems: {},
      purchaseOrderDetailsOriginal: this.props.purchaseOrderDetails,
      purchaseOrderDetails: this.props.purchaseOrderDetails,
      searchItems: this.props.purchaseOrderDetails,
      purchaseOrderItemDetails: [],
      customerDetails: this.props.customerDetails,
      buyerDetails: this.props.buyerDetails,
      isSaving: false,
      displayNoPOsSelectedMessage: false,
      errorMsg: '',
      loadingItemDetails: false,
      showConfirmationModal: false,
      showResetModal: false,
      isDirty: false,
      itemDetailErrors: [],
      qtyErrors: [], // store itemCodes that have qty errors
      weightErrors: [], // store itemCodes that have weight errors
      priceErrors: [], // store itemCodes that have price errors
      showSelectedPOs: false,
      customerFilter: [],
      buyerFilter: [],
      xpoFilter: null
    };

    this.handlePoHeaderChange = this.handlePoHeaderChange.bind(this);
    this.handlePoItemChange = this.handlePoItemChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveConfirmation = this.handleSaveConfirmation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleExpandCollapse = this.handleExpandCollapse.bind(this);
    this.handleXpoFilterChange = this.handleXpoFilterChange.bind(this);
    this.handleXpoFilterClear = this.handleXpoFilterClear.bind(this);
    this.handleCustomerFilterChange = this.handleCustomerFilterChange.bind(this);
    this.handleBuyerFilterChange = this.handleBuyerFilterChange.bind(this);
    this.handleSelectAllCheckBoxClick = this.handleSelectAllCheckBoxClick.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.cancelConfirmationModal = this.cancelConfirmationModal.bind(this);
    this.displayResetModal = this.displayResetModal.bind(this);
    this.cancelResetModal = this.cancelResetModal.bind(this);
    this.handleHideShowSelectedPOs = this.handleHideShowSelectedPOs.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, purchaseOrderDetails } = this.props;
    if (searchId !== prevProps.searchId) {
      if (this.state.isDirty) {
        this.setState({ showResetModal: true });
      } else {
        this.setState({
          purchaseOrderDetailsOriginal: purchaseOrderDetails,
          purchaseOrderDetails,
          searchItems: purchaseOrderDetails
        });
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { searchId, purchaseOrderDetails, customerDetails, buyerDetails } = this.props;
    if (searchId !== prevProps.searchId) {
      this.setState({
        purchaseOrderDetailsOriginal: purchaseOrderDetails,
        purchaseOrderDetails,
        customerDetails,
        buyerDetails,
        searchItems: purchaseOrderDetails
      })
    }
  }

  handleXpoFilterChange = (e) => {
    const xpoFilter = e.target.value;
    this.setState({ xpoFilter: xpoFilter }, () => this.filterOrdersByCriteria());
  }

  handleXpoFilterClear = () => {
    this.setState({ xpoFilter: null }, () => this.filterOrdersByCriteria());
  }

  handleCustomerFilterChange = (selectedList) => {
    this.setState({ customerFilter: selectedList }, () => this.filterOrdersByCriteria());
  }

  handleBuyerFilterChange = (selectedList) => {
    this.setState({ buyerFilter: selectedList }, () => this.filterOrdersByCriteria());
  }

  filterOrdersByCriteria = () => {
    const { purchaseOrderDetails, xpoFilter, customerFilter, buyerFilter } = this.state;
    if (xpoFilter || customerFilter.length || buyerFilter.length) {
      const customerCodes = customerFilter.map(customer => customer.customerCode);
      const buyerIds = buyerFilter.map(buyer => buyer.buyerId);
      const searchItems = purchaseOrderDetails.filter(po =>
        (xpoFilter ? po.purchaseOrderId.toString().includes(xpoFilter) : true) && 
        (customerCodes.length ? customerCodes.includes(po.customerCode) : true) && 
        (buyerIds.length ? buyerIds.includes(po.buyerId) : true)
      );
      this.setState({ searchItems });
    } else {
      this.setState({ searchItems: purchaseOrderDetails });
    }
  }

  // Changes from Line 1 and Line 2 Child Components
  handlePoHeaderChange = (po) => {
    const { purchaseOrderDetails } = this.state;
    const idx = purchaseOrderDetails.map( po => po.purchaseOrderId ).indexOf(po.purchaseOrderId);

    purchaseOrderDetails[idx] = po;
    this.setState({ purchaseOrderDetails, isDirty: true });
  }

  handlePoItemChange = (poItem) => {
    const { purchaseOrderItemDetails } = this.state;
    const idx = purchaseOrderItemDetails.map( item => item.purchaseOrderDetailId ).indexOf(poItem.purchaseOrderDetailId);

    purchaseOrderItemDetails[idx] = poItem;
    this.setState({ purchaseOrderItemDetails, isDirty: true });
  }

  handleSaveConfirmation = () => {
    let { selectedItems, purchaseOrderDetails, purchaseOrderItemDetails, itemDetailErrors, qtyErrors, weightErrors, priceErrors } = this.state;
    let hasErrors = false;
    let removeSelectedItems = [];

    itemDetailErrors = [];
    qtyErrors = [];
    weightErrors = [];
    priceErrors = [];

    // check if something selected
    if (selectedItems.length === 0) {
      hasErrors = true;
      this.setState({ errorMsg: 'Please select a purchase order before submitting.' });
      return;
    }

    let purchaseOrdersWithNullTotalWeight = [];

    selectedItems.forEach(poNumber => {
      const poDetails = purchaseOrderDetails.filter(po => po.purchaseOrderId.toString() === poNumber.toString());

      if (poDetails.length > 0) {
        const po = poDetails[0];

        // Check is user has viewed the Item Details
        const poItemDetails = purchaseOrderItemDetails.filter(item => item.poId.toString() === poNumber.toString());
        
        if (poItemDetails.length === 0) {
          hasErrors = true;
          this.setState({ errorMsg: 'Please click the "Show Items" link to verify that all the item(s) for the purchase order(s) are correct' });
        }

        // Check if any PO items has a null total weight
        if (hasNullTotalWeight(poItemDetails)) purchaseOrdersWithNullTotalWeight.push(poNumber.toString());

        // For each selected PO, 
        // loop through each item details for PO TYPE that is 
        // meat and seafood and check that new qty and new weight are NOT zero
        const validation = validateWeightAndQty(po, poItemDetails);
        removeSelectedItems = [...validation.removeSelectedItems, ...removeSelectedItems];
        itemDetailErrors = [...validation.itemDetailErrors, ...itemDetailErrors];
        weightErrors = [...validation.weightErrors, ...weightErrors];
        qtyErrors = [...validation.qtyErrors, ...qtyErrors];
        priceErrors = [...validation.priceErrors, ...priceErrors];
      }
    });

    // remove PO with validation errors
    // actually, we keep the POs checked and display the errors
    if (removeSelectedItems.length > 0) {
      hasErrors = true;
      this.setState({ itemDetailErrors, qtyErrors, weightErrors, priceErrors });
    }

    // remove POs with null weight but don't throw any errors
    if (purchaseOrdersWithNullTotalWeight.length > 0) {
      const validPOs = selectedItems.filter(item => !purchaseOrdersWithNullTotalWeight.includes(item.toString()));
      this.setState({selectedItems: validPOs});
    }

    if (!hasErrors) this.setState({showConfirmationModal: true});
  }

  handleSave = () => {
    this.setState({ showConfirmationModal: false, isSaving: true});

    const poPromises = [];
    const { selectedItems, purchaseOrderDetails, purchaseOrderItemDetails } = this.state;
    const { currentUser } = this.props;

    selectedItems.forEach(poNumber => {
      const poDetails = purchaseOrderDetails.filter(po => po.purchaseOrderId.toString() === poNumber.toString());

      if (poDetails.length > 0) {
        const po = poDetails[0];
        const poItemDetails = purchaseOrderItemDetails.filter(item => item.poId.toString() === poNumber.toString());

        const body = getOCSBody(po, poItemDetails, currentUser);

        if ('isAutoApproved' in body) {
          poPromises.push(postPurchaseOrder(body).then(() => {
            body.arrivalDate = po.arrivalDate;
            putAuditConfirmedOrders(body);
            po.submitted = true;
          }));
        } else {
          poPromises.push(putPurchaseOrder(po.purchaseOrderId, body).then(() => po.submitted = true));
        }
      };
    });

    Promise.all(poPromises).then(() => {
      // once completed saving all the POs
      this.setState({ 
        isSaving: false,
        selectedItems: [],
        selectAllChecked: false,
        searchCriteria: [],
        itemDetailErrors: [],
        qtyErrors: [],
        weightErrors: [],
        priceErrors: [] });
    });
  }

  displayResetModal = () => {
    this.setState({ showResetModal: true });
  }

  cancelResetModal = () => {
    this.setState({ showResetModal: false });
  }

  handleCancel = () => {
    this.setState({ showResetModal: false, isDirty: false, errorMsg: '' });
    this.props.handleSearch();
  }

  removeErrorMessages = () => this.setState({ errorMsg: '' });

  handleSelectAllCheckBoxClick = () => {
    const { selectAllChecked, searchItems } = this.state;
    if (selectAllChecked) {
      this.setState({
        selectedItems: [],
        selectAllChecked: false
      });
    } else {
      const selectedItems = searchItems.map((item) => item.purchaseOrderId.toString());
      this.setState({
        selectAllChecked: true,
        selectedItems
      });
    }
  };

  handleCheckBoxClick = (e) => {
    this.setState({showConfirmationModal: false});
    const currentItem = e.target.value.toString();
    var { selectedItems } = this.state;

    if (selectedItems.includes(currentItem)) {
      // remove from the array
      selectedItems = selectedItems.filter((item) => item !== currentItem);
      this.setState({ selectedItems, selectAllChecked: false });
    } else {
      selectedItems.push(currentItem);
      this.setState({
        selectedItems,
        selectAllChecked: false
      });
    }
  };

  handleXpoFilterChange = (e) => {
    const { purchaseOrderDetails } = this.state;
    const searchCriteria = e.target.value;
    if (searchCriteria.length > 0) {
      const searchItems = purchaseOrderDetails.filter(po => po.purchaseOrderId.toString().includes(searchCriteria));
      this.setState({ searchItems });
    } else {
      this.setState({ searchItems: purchaseOrderDetails });
    }
  }

  handleXpoFilterClear = () => {
    const { purchaseOrderDetails } = this.state;
    this.setState({ searchItems: purchaseOrderDetails });
  }

  handleExpandCollapse = async (poNumber) => {
    var { showOrderItems, purchaseOrderItemDetails = []} = this.state;

    const itemDetails = purchaseOrderItemDetails.filter(item => item.poId === poNumber);

    if (itemDetails.length === 0) {
      this.setState({ loadingItemDetails: true, loadingItemDetailsFor: poNumber });
      const details = await getPurchaseOrderItemDetails(poNumber); //purchaseOrderDetails.filter(po => po.purchaseOrderId.toString() === poNumber.toString())[0].poDetails;
      purchaseOrderItemDetails = purchaseOrderItemDetails.concat(details);
    }

    showOrderItems[poNumber] = !showOrderItems[poNumber];

    this.setState({ showOrderItems, purchaseOrderItemDetails, loadingItemDetails: false });
  }

  cancelConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  }

  handleHideShowSelectedPOs = () => {
    const { showSelectedPOs } = this.state;
    this.setState({ showSelectedPOs: !showSelectedPOs});
  }

  render() {
    var {
      searchItems,
      selectAllChecked,
      selectedItems,
      purchaseOrderItemDetails,
      isSaving,
      loadingItemDetailsFor,
      loadingItemDetails,
      showConfirmationModal,
      showResetModal,
      customerDetails,
      buyerDetails,
      itemDetailErrors,
      qtyErrors,
      weightErrors,
      priceErrors,
      showSelectedPOs
    } = this.state;

    const { vendorCoolers, countryOfOrigins, searchId } = this.props;

    const divElement = document.querySelector(".search-criteria");
    const offsetHeight = divElement === null ? 95: divElement.offsetHeight + 22 + 95;

    searchItems = showSelectedPOs && selectedItems ? searchItems.filter((item) => selectedItems.includes(item.purchaseOrderId.toString())) : searchItems;

    return (
        <div>
            { this.state.errorMsg.length > 0
              ? <ErrorModal showErrorModal={this.state.errorMsg.length > 0} errorMessages={this.state.errorMsg} closeModal={this.removeErrorMessages} />
              : null
            }

            { isSaving
              ? <StickyWrapper offset={offsetHeight}>
                  <div className="flex-container-row">
                      <Pulse text='Saving PO Data....' />
                  </div>
                </StickyWrapper>
              : null
            }

            { showConfirmationModal && selectedItems.length > 0
              ? <ConfirmationModal showConfirmationModal={showConfirmationModal} selectedItems={selectedItems} handleSave={this.handleSave} cancelConfirmationModal={this.cancelConfirmationModal} />
              : null
            }  
            
            { showResetModal
              ? <ResetModal showResetModal={showResetModal} handleReset={this.handleCancel} cancelResetModal={this.cancelResetModal} />
              : null
            }
            
            <StickyWrapper offset={offsetHeight}>
            { !isSaving
                    ? <div className="flex-container-row actions-bar show">
                        <div className="flex-container-column">
                            <div className="flex-container-row search-container">
                                <SearchInput id="xpo-search" clearSearch={this.handleXpoFilterClear} error={this.errorMsg} handleChange={this.handleXpoFilterChange} placeHolderText="Search By XPO Number" />
                            </div>
                        </div>
                        <div className="flex-container-column">
                          <div className="flex-container-row">
                            <div className="flex-container-column">
                              Buyers: 
                            </div>
                            <div className="flex-container-column select-buyers">
                              <MultiSelectDropDown placeHolderText="Select a Buyer" options={buyerDetails} displayValue="description" id="buyers" onSelect={this.handleBuyerFilterChange} onRemove={this.handleBuyerFilterChange} />
                            </div>
                          </div>
                        </div> 
                        <div className="flex-container-column">
                          <div className="flex-container-row">
                            <div className="flex-container-column">
                              Customers: 
                            </div>
                            <div className="flex-container-column select-customers">
                              <MultiSelectDropDown placeHolderText="Select a Customer" options={customerDetails} displayValue="description" id="customers" onSelect={this.handleCustomerFilterChange} onRemove={this.handleCustomerFilterChange} />
                            </div>
                          </div>
                        </div> 
                        <div className="flex-container-column">
                          <div className="flex-container-row">
                              <PrimaryButton onClick={this.handleSaveConfirmation} name="Submit" />
                              <PrimaryButton onClick={this.displayResetModal} name="Cancel" />
                          </div>
                        </div>
                    </div>
                    : null 
                  }

                  { !isSaving
                    ? <div className="flex-container-row select-all-checkbox">
                        <div className="flex-container-column xpo-count">
                          <div className="flex-container-row">
                            <div className="flex-container-column">{searchItems.filter((po) => !po.submitted).length} XPOs Returned</div>
                            { (selectedItems && selectedItems.length > 0)
                              ? <div className="flex-container-column">
                                <button className="toggle-search-bar" type="button" onClick={this.handleHideShowSelectedPOs}>{ showSelectedPOs ? 'Show All POs' : 'Show Selected POs Only' }</button>
                              </div>
                              : null
                            }
                          </div>
                        </div>  
                        <div className="flex-container-column">
                            <div className="checkbox-container">
                                <label className="body-short-form" htmlFor={1}>
                                    Select All &nbsp;
                                    <input
                                        type="checkbox"
                                        id={1}
                                        value={1}
                                        checked={selectAllChecked}
                                        onChange={this.handleSelectAllCheckBoxClick}
                                      />
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    : null
                  }

                  <div className="item-error-messages">
                    { !isSaving && itemDetailErrors.length > 0
                      ? itemDetailErrors.map((error, index) => { return (<div key={index} className="error-msg">{error}</div>) })
                      : null
                    }
                  </div>
              </StickyWrapper>

              {searchItems.filter((po) => !po.submitted).map((po) => {
                const hasP3Errors = hasNullTotalWeight(purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId));

                return (
                  <div className="flex-container-row po-details" key={po.purchaseOrderId}>

                      <PurchaseOrderDetailsLine1 hasP3Errors={hasP3Errors} searchId={Math.floor(1000000 * Math.random()) * 100000000} handlePoHeaderChange={this.handlePoHeaderChange} searchItems={searchItems} selectedItems={selectedItems} handleCheckBoxClick={this.handleCheckBoxClick} po={po} selectAllChecked={selectAllChecked} vendorCoolers={vendorCoolers} customer={customerDetails.filter(c => c.customerCode === po.customerCode)[0]} />

                      <PurchaseOrderDetailsLine2 searchId={Math.floor(1000000 * Math.random()) * 100000000} buyer={buyerDetails.filter(buyer => buyer.buyerId === po.buyerId)} purchaseOrderItemDetails={purchaseOrderItemDetails} handleExpandCollapse={this.handleExpandCollapse} handlePoHeaderChange={this.handlePoHeaderChange} po={po} />

                      { loadingItemDetails && po.purchaseOrderId === loadingItemDetailsFor
                        ? <Pulse text="Loading Item Details...." />
                        : null
                      }

                      { this.state.showOrderItems[po.purchaseOrderId]
                        ? <PurchaseOrderItemDetails itemErrors={{qtyErrors, weightErrors, priceErrors}} searchId={searchId} handlePoItemChange={this.handlePoItemChange} countryOfOrigins={countryOfOrigins} poType={po.poType} itemDetails={purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId)} />
                        : null }

                  </div>
              )}
            )}
        </div>
    );
  }
}

export default PurchaseOrderDetails;
