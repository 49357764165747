/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import moment from 'moment';
import { Logger } from 'aws-amplify';
import { SingleDatePicker } from 'react-dates';
import {
  DropDown
} from 'wf-react-component-library';

import 'react-dates/lib/css/_datepicker.css';
import './PurchaseOrderDetails.scss';

const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetailsLine1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      po: this.props.po,
      focused: false,
      invoiceNumber: ''
    };

    this.handleInvoiceNumberChange = this.handleInvoiceNumberChange.bind(this); 
    this.formatDate = this.formatDate.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
  }

  handleInvoiceNumberChange = (e) => {
    const { po } = this.state;
    const invoiceNumber = e.target.value;
    po['invoiceNumber'] = invoiceNumber;
    this.setState({ po, invoiceNumber });
    this.props.handlePoHeaderChange(po);
  }

  handleCheckBoxClick = (e) => {
    this.props.handleCheckBoxClick(e);
  }

  formatDate = (deliveryDate) => {
    const dt = moment(deliveryDate);
    return dt.format('MM/DD/YYYY');
  }

  render() {
    const { po, invoiceNumber } = this.state;

    const { selectAllChecked, selectedItems, customer, hasP3Errors } = this.props;

    return (
        <div className="flex-container-row po-data po-item-count">
            <div className="flex-container-column top-level">
                <div className="flex-container-row po-details-2">
                <div className="flex-container-column">
                        Invoice Number:
                    </div>
                    <div className="flex-container-column pickup-number">
                        <input className="po-input" id={`invoiceNumber-${po.purchaseOrderId}`} onFocus={ this.handleFocus } type="text" value={invoiceNumber} onChange={this.handleInvoiceNumberChange} />
                    </div>
                    <div className="flex-container-column">
                        Ship to: {customer === undefined ? null : `${customer.description} - `}{`${po.shipAddress.address1}, ${po.shipAddress.city}, ${po.shipAddress.state} ${po.shipAddress.zipCode}, ${po.shipAddress.country}`}
                    </div>
                    <div className="flex-container-column po-header-label">
                        Load Date: {this.formatDate(po.loadDate)}
                    </div>
                    <div className="flex-container-column po-header-label">
                        Arrival Date: {this.formatDate(po.arrivalDate)}
                    </div>
                    <div className="flex-container-column po-header-label">
                        Truck Status: {po.sh_truckStatusCode === 'Q' ? 'Ready To Price' : (po.sh_truckStatusCode === 'T' ? 'Sent' : 'Received')}
                    </div>
                </div>
            </div>

            { hasP3Errors
                ? <div style={{textAlign: 'center'}} className="p3-error-msg error-msg">This PO cannot be submitted. Please contact your Whole Foods representative for additional help.</div>
                : <div className="flex-container-column item-count top-level">
                    <div className="checkbox-container">
                        <label className="body-short-form" htmlFor={po.purchaseOrderId}>
                            <input
                                data-testid={`checkbox-${po.purchaseOrderId}`}
                                type="checkbox"
                                id={po.purchaseOrderId}
                                value={po.purchaseOrderId}
                                checked={
                                    selectAllChecked ||
                                    selectedItems.includes(po.purchaseOrderId.toString())
                                }
                                onChange={this.handleCheckBoxClick}
                            />
                            <span />
                        </label>
                    </div>
                </div>
            }
        </div>
    )}
}

export default PurchaseOrderDetailsLine1;
