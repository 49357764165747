/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */

import React from 'react';
import { Logger } from 'aws-amplify';
import {
  DropDown, TextInput
} from 'wf-react-component-library';
import currencyFormatter from '../utilities/currency-formatter';
import { validateItem } from '../services/validation/purchase-order-details-validation';

const logger = new Logger('PurchaseOrderItemDetails.js');

class PurchaseOrderItemDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    var { item } = this.state;
    const { poType } = this.props;

    const val = e.target.value;
    const propName = e.target.id.split('-').length === 1 ? e.target.name.split('-')[0] : e.target.id.split('-')[0];
    
    if (propName === 'countryOfOrigin') {
        const hasItemProperty = item.item && item.item !== null;
        if (!hasItemProperty) item.item = {};
        item.item['newCountryOfOrigin'] = val; //string for Country of Origin
    } else if (propName === 'qty') {
          item['newQty'] = val

        // If the Vendor enters zero in the New Weight field, automatically make the New Qty field zero.
        if (parseFloat(val) === 0) item['newTotalWeight'] = 0;
    } else if (propName === 'weight') {
        item['newTotalWeight'] = val
        
        // If the Vendor enters zero in the New Weight field, automatically make the New Qty field zero.
        if (parseFloat(val) === 0) item['newQty'] = 0;
    } else {
        item['newPrice'] = val
    }

    this.setState({ item });

    this.props.handleChange(item);
  }

  render() {
    const { item, qtyError, weightError, priceError } = this.state;
    const { countryOfOrigins, poType, itemErrors } = this.props;

    const lineItemErrors = validateItem(item);
    let { qtyErrors, weightErrors, priceErrors } = lineItemErrors;

    weightErrors = [...itemErrors.weightErrors, ...weightErrors];
    qtyErrors = [...itemErrors.qtyErrors, ...qtyErrors];
    priceErrors = [...itemErrors.priceErrors, ...priceErrors];

    const countryOfOrigin = item.item ? (item.overrideOriginName === null ? item.item.originName : item.overrideOriginName) : (item.overrideOriginName === null ? '' : item.overrideOriginName)

    const isBurdenItem = item.item ? ((item.item.itemGroupCode === "M" || item.item.itemGroupCode === "MB" || item.item.itemGroupCode === "SB") ? true : false ) : false

    const isWeightUnitType = item.unitType === 'WT' ? true : false;
    const hasTotalWeight = 'totalWeight' in item && item.totalWeight !== null ? true : false;

    const qtyClassName = ((qtyErrors.includes(item.purchaseOrderDetailId.toString())) || qtyError) ? 'item-details-col qty error' : 'item-details-col qty';
    const weightClassName = ((weightErrors.includes(item.purchaseOrderDetailId.toString())) || weightError) ? 'item-details-col weight error' : 'item-details-col weight';
    const priceClassName = ((priceErrors.includes(item.purchaseOrderDetailId.toString())) || priceError) ? 'item-details-col price error' : 'item-details-col price';

    return (
        <td style={{width: '100%'}} colSpan={11}>
            <table cellPadding="0" cellSpacing="0" width="100%">
                <colgroup>
                    <col span="1" style={{ width: '8%' }} />
                    <col span="1" style={{ width: '30%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '5%' }} />
                    <col span="1" style={{ width: '3%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '10%' }} />
                    <col span="1" style={{ width: '4%' }} />
                    <col span="1" style={{ width: '4%' }} />
                </colgroup>
                <tbody>
                    <tr className="item-details-row">
                        <td>{item.item ? item.item.itemCode : null}</td>
                        <td>{item.item ? item.item.description : item.itemDesc}</td>
                        <td>{item.arQty}</td>
                        <td className={qtyClassName}><TextInput value={item.newQty !== undefined ? item.newQty.toString() : item.arQty.toString()} handleChange={this.handleChange} className="po-input item-details error qty" id={`qty-${item.purchaseOrderDetailId}`} type="text" /></td>
                        <td>{item.totalWeight}</td>
                        { isWeightUnitType
                          ? <td className={weightClassName}>
                            <TextInput isDisabled={!isWeightUnitType || isBurdenItem} value={item.newTotalWeight !== undefined ? item.newTotalWeight.toString() : (itemErrors.weightErrors.includes(item.purchaseOrderDetailId.toString()) ? '' : (hasTotalWeight ? item.totalWeight.toString() : null))} handleChange={this.handleChange} className="po-input item-details weight" id={`weight-${item.purchaseOrderDetailId}`} type="text" />
                          </td>
                          : <td className="item-details-col price">{ hasTotalWeight ? item.totalWeight.toString() : null }</td>
                        }
                        <td>{item.unitType}</td>     
                        <td>{isBurdenItem ? null : countryOfOrigin}</td>
                        <td className="item-details-col">
                            <div className="flex-container-column coo">
                              { isBurdenItem ? null
                                : <DropDown handleChange={this.handleChange}  className="po-input drop-down coo" value={item.item && item.item.newCountryOfOrigin ? item.item.newCountryOfOrigin : countryOfOrigin} id={`countryOfOrigin-${item.purchaseOrderDetailId}`} options={countryOfOrigins} placeHolderText="Select a COO"  />
                              }    
                            </div>
                        </td>
                        <td>{currencyFormatter.format(item.price)}</td>
                        <td className={priceClassName}>
                          <TextInput value={item.newPrice !== undefined ? item.newPrice.toString() : item.price.toString()} handleChange={this.handleChange} className="po-input item-details price" id={`price-${item.purchaseOrderDetailId}`} type="text" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    );
  }
}

export default PurchaseOrderItemDetail;
