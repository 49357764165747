/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */

import React from 'react';
import { Logger } from 'aws-amplify';
import PurchaseOrderItemDetail from './PurchaseOrderItemDetail';


import './PurchaseOrderItemDetails.scss';

// const logger = new Logger('PurchaseOrderItemDetails.js');

class PurchaseOrderItemDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryOfOrigins: this.props.countryOfOrigins,
      itemDetails: this.props.itemDetails
    };

    this.handleChange = this.handleChange.bind(this); 
  }

  handleChange = (poItem) => {
    const { itemDetails } = this.state;
    const idx = itemDetails.map( item => item.purchaseOrderDetailId ).indexOf(poItem.purchaseOrderDetailId);

    itemDetails[idx] = poItem;
    this.setState({ itemDetails });

    this.props.handlePoItemChange(poItem);
  }

  render() {
    const { itemDetails } = this.state;
    const { countryOfOrigins, poType, itemErrors } = this.props;

    return (
        <div className="flex-container-row item-table-container show">
            <div className="flex-container-column">
                <table className="item-table" cellPadding="0" cellSpacing="0" width="100%">
                    <colgroup>
                        <col span="1" style={{ width: '8%' }} />
                        <col span="1" style={{ width: '30%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '5%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '10%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '4%' }} />
                    </colgroup>
                    <tbody>
                        <tr className="item-table-header">
                            <td>Item Code</td>
                            <td>Description</td>
                            <td>Order Qty</td>
                            <td>New Qty</td>
                            <td>Weight</td>
                            <td>New Weight</td>
                            <td>Unit</td>
                            <td>COO</td>
                            <td>New COO</td>
                            <td>Buy Price</td>
                            <td>New Price</td>
                        </tr>

                        { itemDetails.map (item =>
                            <tr key={item.purchaseOrderDetailId}>
                                <PurchaseOrderItemDetail itemErrors={itemErrors} item={item} poType={poType} countryOfOrigins={countryOfOrigins} handleChange={this.handleChange} />
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
  }
}

export default PurchaseOrderItemDetails;
