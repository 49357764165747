import React from 'react';
import { PrimaryButton, SecondaryButton, Modal } from 'wf-react-component-library';

import './ConfirmationModal.scss';

const ConfirmationModal = (props) => (
  <div className="confirmation-modal">
    <Modal show={props.showConfirmationModal} width="50%">
      <div className="flex-container-row item-details-container show">
        <div className="flex-container-row row-details">
          <div className="headline-small intro">The following purchase orders will be submitted with your changes:</div>
          { props.selectedItems.map(item => {
            return (<div className="headline-smaller" key={item}>{item}</div>)
          })}
          <div className="headline-small alert">YOU CANNOT EDIT THESE PURCHASE ORDERS ONCE THEY ARE SUBMITTED</div>
        </div>

        <div className="flex-container-row buttons">
          <PrimaryButton onClick={props.handleSave} name="Submit" />
          <SecondaryButton onClick={props.cancelConfirmationModal} name="Cancel" />
        </div>
      </div>
    </Modal>
  </div>
);

export default ConfirmationModal;
