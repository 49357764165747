/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger, Auth } from 'aws-amplify';
import { getP3Data } from '../services/api/SCAApiService';
import { keyValueMapper } from '../utilities/key-value-util';
import { Pulse } from 'wf-react-component-library';

import 'react-dates/lib/css/_datepicker.css';
import './Dashboard.scss';

import Search from './Search';

const logger = new Logger('Dashboard.js');

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = { 
      countryOfOrigins: {},
      vendorCoolers: {},
      customerDetails: [],
      buyerDetails: [],
      loadingPage: false,
      loadingMessage: ""
    };
  }

  componentDidMount = () => {
    this.setState({loadingPage: true, loadingMessage:'Verifying user access...'});
    Auth.currentAuthenticatedUser().then((user) => {
      logger.info('USER INFO:', user.signInUserSession.idToken.payload);
      logger.info('VENDOR CODE:', user.signInUserSession.idToken.payload['custom:vendorId']);
      this.setState({ loadingMessage:'Accessing P3 Data....', currentUser: user.signInUserSession.idToken.payload.email, currentUserVendorCode: user.signInUserSession.idToken.payload['custom:vendorId']});
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });

    getP3Data().then((resp) => {
      logger.debug('p3Data', resp);
      const vendorCoolers = keyValueMapper(
        resp.coolers,
        'code',
        'code',
      );
      logger.debug('coolers mapped', vendorCoolers);
      const countryOfOrigins = keyValueMapper(
        resp.countryOfOrigins,
        'name',
        'name',
      );
      logger.debug('coo mapped', countryOfOrigins);
      const customerDetails = resp.customers;
      const buyerDetails = resp.buyers;
      const vendorInfo = resp.vendor
      this.setState({ loadingMessage: '', loadingPage: false, customerDetails, buyerDetails, vendorInfo, vendorCoolers, vendorCoolersDetails: resp.coolers, countryOfOrigins, cooDetails: resp.countryOfOrigins });
    });   
  }

  render() {
    const {
      countryOfOrigins, vendorCoolers, currentUser, currentUserVendorCode, customerDetails, buyerDetails, vendorInfo, loadingPage, loadingMessage
    } = this.state;

    logger.info('Dashboard State', this.state)

    return (
      <div className="App">
        { loadingPage
          ? <Pulse text={loadingMessage} />
          : null
        }

        { customerDetails.length === 0 || buyerDetails.length === 0
          ? null
          : <Search customerDetails={customerDetails} buyerDetails={buyerDetails} vendorDetails={vendorInfo} currentUserVendorCode={currentUserVendorCode} currentUser={currentUser} countryOfOrigins={countryOfOrigins} vendorCoolers={vendorCoolers} />
        }
        </div>
    );
  }
}

export default Dashboard;
