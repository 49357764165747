/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import React from 'react';
import { Logger } from 'aws-amplify';
import currencyFormatter from '../utilities/currency-formatter';

// const logger = new Logger('PurchaseOrderDetails.js');

const PRICING_STATUS_CODE_AR = "Final-AR";
const PRICING_STATUS_CODE_P = "Preliminary";
const PRICING_STATUS_CODE_O = "Open";
const PRICING_STATUS_CODE_F = "Final";

class PurchaseOrderDetailsLine2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      po: this.props.po
    };
    
    this.handleFocus = this.handleFocus.bind(this);
    this.getPricingStatus = this.getPricingStatus.bind(this);
  }

  handleFocus = (e) => e.target.select();
  
  getPricingStatus = (code) => {
    switch(code) {
        case 'AR':
          return PRICING_STATUS_CODE_AR;
        case 'P':
            return PRICING_STATUS_CODE_P;
        case 'O':
            return PRICING_STATUS_CODE_O;
        case 'F':
            return PRICING_STATUS_CODE_F;
        default:
          return 'None'
      }
  }

  render() {
    const { po } = this.state;

    const { purchaseOrderItemDetails } = this.props;

    return (
        <div className="flex-container-row po-data po-item-count">
            <div className="flex-container-column top-level">
                <div className="flex-container-row po-details-2">
                    <div className="flex-container-column">
                        PO#: {po.purchaseOrderId}
                    </div>
                    <div className="flex-container-column">
                        RPO#: {po.wo_rpo}
                    </div>
                    <div className="flex-container-column">
                        Pickup#: {po.pickupNumber}
                    </div>
                    <div className="flex-container-column">
                        Vendor: {po.vendorCode}
                    </div>
                    <div className="flex-container-column">
                        Total Cases: {po.totalCartons}
                    </div>
                    <div className="flex-container-column">
                        Total Weight: {po.totalWeight}
                    </div>
                    <div className="flex-container-column">
                        Total Pallets: {po.totalPallets}
                    </div>
                    <div className="flex-container-column">
                        Total Cost: {currencyFormatter.format(po.totalCost)}
                    </div>
                    <div className="flex-container-column ">
                        Pricing Status: {this.getPricingStatus(po.wo_pricingStatusCode)}
                    </div>
                    <div className="flex-container-column">
                        PO Type: {po.poType}
                    </div>
                </div>
            </div>

            <div className="flex-container-column item-count top-level">
                <button className="toggle-items" type="button" onClick={() => this.props.handleExpandCollapse(po.purchaseOrderId)}>Show {purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId).length === 0 ? '' : purchaseOrderItemDetails.filter(item => item.poId === po.purchaseOrderId).length} Items</button>
            </div>
        </div>
    )}
}

export default PurchaseOrderDetailsLine2;
