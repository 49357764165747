/* eslint-disable arrow-body-style */
import { API, Auth, Logger } from 'aws-amplify';

const logger = new Logger('SCAApiService');
const SCA_API_NAME = 'SCAApiProxy';

/**
 * Request Options to include authorization headers on all requests
 */
const requestOptions = () => {
  logger.debug('requestOptions..');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'idToken': `${user.signInUserSession.idToken.jwtToken}`,
          'x-api-authorization': `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
        },
      };
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
};

/**
 * Request Wrapper to call the request options on all requests
 * @param {*} request
 */
const requestWrapper = (request) => {
  return requestOptions().then((options) => request(options));
};

const getShipmentsBySearchCriteria = (params) => {
  return requestWrapper(async (options) => {
    let {
      startDate,
      endDate,
      dateType
    } = params;
    // format date types
    const fromDateParam = dateType === "0"
      ? 'loadDateFrom'
      : 'arrivalDateFrom';
    const toDateParam = dateType === "0"
      ? 'loadDateTo'
      : 'arrivalDateTo'

    // form url and queryParams
    const url = `/shipments`;
    let queryParams = '?';
    queryParams = startDate
      ? `${queryParams}${fromDateParam}=${startDate}`
      : queryParams;
    queryParams = startDate && endDate
      ? `${queryParams}&`
      : queryParams;
    queryParams = endDate
      ? `${queryParams}${toDateParam}=${endDate}`
      : queryParams;
      
      try {
        const resp = await API.get(SCA_API_NAME, `${url}${queryParams}`, options);
        return resp;
      } catch {
        console.log('Problem with API');
      }
  });
};

const getP3Data = () => {
  return requestWrapper((options) => {
    return API.get(SCA_API_NAME, `/p3data`, options);
  });
};

const getPurchaseOrderItemDetails = (purchaseOrderId) => {
  return requestWrapper((options) => {
    return API.get(SCA_API_NAME, `/purchase-orders/${purchaseOrderId}/details`, options);
  });
};

export {
  requestWrapper,
  getShipmentsBySearchCriteria,
  getP3Data,
  getPurchaseOrderItemDetails
};
